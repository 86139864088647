import request from "@/utils/request"

export default {
        //分页查询spy数据
        getDataList: (params) => request.get('/tadspyinfo/getDataList', params),
        // 获取spy总数
        getDataTotal: (params) => request.get('/tadspyinfo/getDataTotal', params),
        // 额外统计平均 po num;以及该域名存在的时间
        getAveragePo: (params) => request.get('/tadspyinfo/getAveragePo', params),
        // 获取小时数据
        getHourData: (params) => request.get('/tadspyinfo/getHourData', params),
        // 获取我们的域名
        getWebSort: (params) => request.get('/tadspyinfo/getWeWebSort', params),
        // 根据域名关键词获取我们的域名
        getWeDomain: (params) => request.get('/tadspyinfo/searchWeDomain', params),
        // 根据域名关键词获取对手的域名
        getOpponentDomain: (params) => request.get('/tadspyinfo/searchOpponentDomain', params),
        // 根据域名关键词获取review的域名
        getReviewDomain: (params) => request.get('/tadspyinfo/searchReviewDomain', params),
        // 根据域名关键词获取其他的域名
        getRestsDomain: (params) => request.get('/tadspyinfo/searchRestsDomain', params),
        // 获取对手的域名
        getRivalWebSort: (params) => request.get('/tadspyinfo/getRivalWebSort', params),
        // 获取review的域名
        getReviewWeb: (params) => request.get('/tadspyinfo/getReviewWeb', params),
        // 获取其他域名
        getRestsWeb: (params) => request.get('/tadspyinfo/getRestsWeb', params),
        // 添加到其他域名
        addRestsWeb: (data) => request.get(`/restsWeb/addRestsWeb?${data}`),
        // 添加到reivew域名
        addReviewWeb: (data) => request.get(`/reviewWeb/addReviewWeb?${data}`),
        // 移出reivew域名
        deleteReview: (data) => request.get(`/reviewWeb/deleteReview?${data}`),
        // 移出reivew域名
        deleteRests: (data) => request.get(`/restsWeb/deleteRests?${data}`),
        // 获取百分比表格数据
        getWebNumber: (params) => request.get('/tadspyinfo/getWebNumber', params),
        // 获取所有域名数据
        getDomain: (params) => request.get('/tadspyinfo/getDomain', params),
        // 获取所有目标域名数据
        getAll: (params) => request.get('/tisGoalDomain/getAll', params),
        // 获取所有总数量
        getTotal: (params) => request.get('/tisGoalDomain/getTotal', params),
        // 修改目标域名状态值 
        updateType: (params) => request.get('/tisGoalDomain/updateType', params),
        // 获取domain数据
        selectDomainInfo: (params) => request.get('/whois/selectDomainInfo', params),
        // 获取domain数据
        searchingDomain: (params) => request.get('/whois/searchingDomain', params),
        // 通过url参数获取数据 
        getOneDomainInformation: (params) => request.get('/tadspyinfo/getOneDomainInformation', params),
        // 获取nameServers数据
        selectNameServicesInfo: (data) => request.get(`/whois/selectNameServicesInfo?${data}`),
        // 获取cfd-数量表格
        getCfdClassify: (params) => request.get('/tadspyinfo/getCfdClassify', params),
        // 获取domain表格
        getCfdDetail: (params) => request.get('/tadspyinfo/getCfdDetail', params),
        // 获取cfd类别
        getCfdCategory: () => request.get('/tadspyinfo/getCfdCategory'),
        // 获取adsaccname
        getTadsaccname: () => request.get('/adsaccname/getTadsaccname'),
        // 添加adsaccname
        addAdsaccname: (params) => request.get('/adsaccname/addTadsaccname', params),
        // 删除adsaccname
        deleteTadsaccname: (data) => request.get(`/adsaccname/deleteTadsaccname?${data}`),
        // 获取adsaccname详情
        getAdsaccnameDetail: (params) => request.get('/tadspyinfo/getAdsaccnameDetail', params),
        // 获取adsaccname数量
        getAdsaccnameClassify: (params) => request.get('/tadspyinfo/getAdsaccnameClassify', params),
        // 获取Bing详情
        getBingDetail: (params) => request.get('/tadspyinfo/getBingDetail', params),
        // 获取Bing数量
        getBingClassify: (params) => request.get('/tadspyinfo/getBingClassify', params),
        // 移除domain
        addBingRemove: (params) => request.get('/bingremove/save', params),
        // 获取点击对手详情
        getClickLogTb: (params) => request.get('/tClicklogtb/getClickLogTb', params),
        // 获取点击对手详情
        getClickLogTbClassify: (params) => request.get('/tClicklogtb/getClickLogTbClassify', params),
        // 上传数据
        upload: (formData) => request.post('/tDomainDetails/upload', formData),
        // 上传feed数据
        uploadFeedTable: (formData) => request.post('/feed/uploadFeedTable', formData),
        // 上传feedInfo数据
        uploadFeedInfoTable: (formData) => request.post('/tFeedInfo/uploadFeedTable', formData),
        // 获取offer详情
        getOfferDetails: (params) => request.get('/spyoffer/getOfferDetails', params),
        // 获取点击对手详情
        getBestOffer: (params) => request.get('/spyoffer/getBestOffer', params),
        // 获取上传domain数据列表
        getDomainList: (params) => request.get('/tDomainDetails/getDomainList', params),
        // 获取上传domain数据列表
        getDomainDetails: (params) => request.get('/tDomainDetails/getDomainDetails', params),
        // 获取bing广告数据列表
        dataDisplay: () => request.get('/advertiser/dataDisplay'),
        // 通过id获取bing广告数据
        getDataByAdvertiserId: (params) => request.get('/advertiser/getDataByAdvertiserId', params),
        // 获取广告商投放信息
        getAdvertisement: (params) => request.get('/advertisement/getAdvertisement', params),
        // 根据域名获取广告商投放详细信息
        getAdvertisementInfo: (params) => request.get('/advertisement/getAdvertisementInfo', params),
        //添加advertiserId和advertiserName对应关系
        addAdvertiserName: (params) => request.get('/advertiserName/save', params),
        //获取advertiserId和advertiserName对应关系
        getAdvertisName: () => request.get('/advertiserName/list'),
        //删除advertiserName对应关系
        deleteAdvertiserName: (params) => request.get('/advertiserName/delete', params),
        //添加不存入domain
        dropDomain: (data) => request.get(`/dropDomain/save?${data}`),
        //获取丢弃的domain
        getDropDomain: () => request.get('/dropDomain/list'),
        //删除丢弃的domain
        deleteDropDomain: (params) => request.get('/dropDomain/delete', params),
        //获取关键词列表
        getKeywordListByDelete: (data) => request.get(`/spyconfig/getKeywordListByDelete?${data}`),
        //修改关键词信息
        updateKeywordDataById: (params) => request.get('/spyconfig/updateKeywordDataById', params),
        //新增关键词配置
        insertKeywordDataById: (params) => request.get('/spyconfig/insertKeywordDataById', params),
        //删除关键词配置
        deleteKeywordList: (data) => request.get(`/spyconfig/deleteKeywordList?${data}`),
        //获取浏览器列表
        getBrowserListByDelete: () => request.get('/spyconfig/getBrowserListByDelete'),
        //修改浏览器信息
        updateBrowserDataById: (params) => request.get('/spyconfig/updateBrowserDataById', params),
        //新增浏览器配置
        insertBrowserDataById: (params) => request.get('/spyconfig/insertBrowserDataById', params),
        //删除浏览器配置
        deleteBrowserList: (data) => request.get(`/spyconfig/deleteBrowserList?${data}`),
        //获取角度
        getAllAngle: () => request.get(`/tadspyinfo/getAllAngle`),
        //获取平台
        getAllPlateform: () => request.get(`/tadspyinfo/getAllPlateform`),
        //获取国家
        getAllGeo: () => request.get(`/tadspyinfo/getAllGeo`),
        //获取类别
        getAllCategory: () => request.get(`/tadspyinfo/getAllCategory`),
        //添加Feed文件标签
        addTag: (params) => request.post(`/feedTag/saveOrUpdate`, params),
        //获取全部标签
        getTagList: () => request.get(`/feedTag/list`),
        //获取数据
        getFeedList: (data) => request.get(`/feed/getList?${data}`),

        getWebsiteUrlInfo: (params) => request.get(`/feed/getListByWebsiteUrl`, params),

        getTextByWebsiteUrl: (params) => request.get(`/feed/getTextByWebsiteUrl`, params),

        getUploadFeedFileName: () => request.get(`/feed/getUploadFeedFileName`),

        deleteFeedFileByName: (params) => request.get(`/feed/deleteFeedFileByName`, params),

        getFeedUrlInfo: (params) => request.get(`/tFeedInfo/getList`, params),

        getFeedUrlDomain: (params) => request.get(`/tFeedInfo/getDomain`, params),

        getFeedAppTypeList: () => request.get(`/feed/app/type`),

        uploadFeedAppTable: (formData) => request.post('/feed/app/upload/file', formData),

        getFeedAppCategory: () => request.get(`/feed/app/category`),

        getFeedAppDetail: (params) => request.get(`/feed/app/view/details`, params),

        feedAppAddBlacklist: (params) => request.get(`/feed/app/add/black/list`, params),

        feedAppAddWhitelist: (params) => request.get(`/feed/app/add/white/list`, params),

        feedAppAddlist: (params) => request.get(`/feed/app/add/list`, params),
        
        feedAppExport: (params) => request.exportExcel(`/feed/app/export`, params),

        uploadFeedAppBlacklistTable: (formData) => request.post('/feed/app/upload/blacklist/file', formData),
}